import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { parallelActions } from './../../app/store';

import { Navigation } from './../components/navigation';
import { UserInfo } from './../components/userInfo';
import { Spinner } from './../components/spinner';
import { Toasts } from './../components/toasts';

import { Login } from './../login/login';
import { prepareLoginFormAction } from './../login/loginSlice';

import { Users } from './../users/users';
import { Reports } from './../reports/reports';
import { Emergency } from './../emergency/emergency';
import { Applications } from './../applications/applications';
import { Advertisements } from './../advertisements/advertisements';

import { selectMainState, verifyLoginAction, initializeAction, doLogoutAndVerifyLoginAction } from './mainSlice';

import styles from './main.css';

function getContainer(mainState, dispatch) {
    if (mainState.spinner) {
        return (
        <div className="container-fluid">
            <Spinner />
        </div>
        )
    }

    if (!mainState.loggedIn) {
        return (
        <div className="row">
            <div className="col-md-5"></div>
            <div className="col-md-7">
                <div className="full-height d-flex justify-content-start align-items-center">
                    <Login />
                </div>
            </div>
        </div>
        )
    }

    let selectedPageContainer;
    let selectedPageHeader;
    switch (mainState.selectedPage) {
      case 'users':
        selectedPageContainer = <Users />;
        selectedPageHeader = 'Users';
        break;
      case 'reports':
        selectedPageContainer = <Reports />;
        selectedPageHeader = 'Reports';
        break;
      case 'emergency':
        selectedPageContainer = <Emergency />;
        selectedPageHeader = 'Emergency';
        break;
      case 'applications':
        selectedPageContainer = <Applications />;
        selectedPageHeader = 'Projects';
        break;
      case 'advertisements':
        selectedPageContainer = <Advertisements />;
        selectedPageHeader = 'Manage Ads';
        break;
      default:
        selectedPageContainer = undefined;
        selectedPageHeader = undefined;
    }

    const onLogout = () => dispatch(parallelActions([
        doLogoutAndVerifyLoginAction(),
        prepareLoginFormAction()
    ], 'Main::logout'));

    let selectedPageContainerWrapper;
    if (selectedPageContainer) {
        selectedPageContainer = (
        <div className="col p-3-top right-side-container">
            <h1 className="p-3-much-left">{selectedPageHeader}</h1>

            <div className="p-4-no-bottom bg-lightgray">
                <div className="height-128 bg-white">
                    <div className="bg-white">
                        {selectedPageContainer}
                    </div>
                </div>
            </div>
        </div>
        );
    }

    return (
    <div className="row">
        <div className="col-auto no-padding">
            <div className="navigation-left-panel">
                <UserInfo />

                <Navigation role={mainState.me.role} selectedPage={mainState.selectedPage} onLogout={onLogout}/>
            </div>
        </div>

        {selectedPageContainer}
    </div>
    )
}

export default function Main() {
    const mainState = useSelector(selectMainState);
    const dispatch = useDispatch();

    if (mainState.needLoginCheck && !mainState.needLoginCheckInProgress) {
        dispatch(parallelActions([
            verifyLoginAction(),
            prepareLoginFormAction()
        ], 'Main::initiateLoginCheck'));
    }

    let innerContainer = getContainer(mainState, dispatch);

    return (
    <div className="container-fluid">
        {innerContainer}

        <Toasts />
    </div>
    )
}
