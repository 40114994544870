import { baseFetch, baseFetchText } from './../common/api';

function loadAdvertisementsPromise(resolve, reject, applicationIdFilter) {
    let query = '';
    if (applicationIdFilter && applicationIdFilter > -1) {
        query = `?applicationId=${applicationIdFilter}`;
    }
    return baseFetch(resolve, reject, `/api/admin/advertisements${query}`);
}

function loadAdTypesPromise(resolve, reject) {
    return baseFetch(resolve, reject, '/api/admin/ad-types');
}

function loadApplicationsPromise(resolve, reject) {
    return baseFetch(resolve, reject, '/api/admin/applications?sortByNameAsc=true');
}

function saveNewAdvertisementPromise(resolve, reject, requestBody) {
    return baseFetchText(resolve, reject, '/api/admin/advertisements', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestBody) });
}

function changeAdvertisementIsActivePromise(resolve, reject, id, isActive) {
    return baseFetchText(resolve, reject, `/api/admin/advertisements/${id}/active/${isActive}`, { method: 'PUT' });
}

function changeAdvertisementIsProbabilityStartsWithAdsPromise(resolve, reject, id, isProbabilityStartsWithAds) {
    return baseFetchText(resolve, reject, `/api/admin/advertisements/${id}/probabilityStartsWithAds/${isProbabilityStartsWithAds}`, { method: 'PUT' });
}

function loadAdvertisementPromise(resolve, reject, id) {
    return baseFetch(resolve, reject, `/api/admin/advertisements/${id}`);
}

function deleteAdvertisementPromise(resolve, reject, id) {
    return baseFetchText(resolve, reject, `/api/admin/advertisements/${id}`, { method: 'DELETE' });
}

function updateAdvertisementPromise(resolve, reject, id, requestBody) {
    return baseFetchText(resolve, reject, `/api/admin/advertisements/${id}`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestBody) });
}

function saveOrUpdateAdvertisementCall(id, name, applicationId, adTypeId, admobAdIds, comments, probability, active, probabilityStartsWithAds, applicationIdFilter) {
    const requestBody = {
        id: id,
        name: name,
        applicationId: parseInt(applicationId),
        adTypeId: parseInt(adTypeId),
        admobAdIds: admobAdIds.split(','),
        comments: comments,
        probability: parseInt(probability),
        active: active,
        probabilityStartsWithAds: probabilityStartsWithAds
    };

    return new Promise((resolve, reject) => {
        (
            id === undefined
                ? saveNewAdvertisementPromise(resolve, reject, requestBody)
                : updateAdvertisementPromise(resolve, reject, id, requestBody)
        )
            .then(__text => loadAdvertisementsPromise(resolve, reject, applicationIdFilter))
            .then(json => resolve(json))
    });
}

export function loadAdvertisementsCall(applicationIdFilter) {
    return Promise.all([
        new Promise((resolve, reject) => {
            loadAdvertisementsPromise(resolve, reject, applicationIdFilter)
                .then(json => resolve(json))
        }),
        new Promise((resolve, reject) => {
            loadApplicationsPromise(resolve, reject)
                .then(json => resolve(json))
        })
    ]);
}

export function loadAdTypesAndApplicationsCall() {
    return Promise.all([
        new Promise((resolve, reject) => {
            loadAdTypesPromise(resolve, reject)
                .then(json => resolve(json))
        }),
        new Promise((resolve, reject) => {
            loadApplicationsPromise(resolve, reject)
                .then(json => resolve(json))
        })
    ]);
}

export function saveNewAdvertisementCall(name, applicationId, adTypeId, admobAdIds, comments, probability, active, probabilityStartsWithAds, applicationIdFilter) {
    return saveOrUpdateAdvertisementCall(undefined, name, applicationId, adTypeId, admobAdIds, comments, probability, active, probabilityStartsWithAds, applicationIdFilter);
}

export function changeAdvertisementIsActiveCall(id, index, isActive) {
    return new Promise((resolve, reject) => {
        changeAdvertisementIsActivePromise(resolve, reject, id, isActive)
            .then(__text => resolve({ index: index, isActive: isActive }))
    });
}

export function changeAdvertisementIsProbabilityStartsWithAdsCall(id, index, isProbabilityStartsWithAds) {
    return new Promise((resolve, reject) => {
        changeAdvertisementIsProbabilityStartsWithAdsPromise(resolve, reject, id, isProbabilityStartsWithAds)
            .then(__text => resolve({ index: index, isProbabilityStartsWithAds: isProbabilityStartsWithAds }))
    });
}

export function loadAdvertisementCall(id) {
    return Promise.all([
        new Promise((resolve, reject) => {
            loadAdvertisementPromise(resolve, reject, id)
                .then(json => resolve(json))
        }),
        new Promise((resolve, reject) => {
            loadAdTypesPromise(resolve, reject)
                .then(json => resolve(json))
        }),
        new Promise((resolve, reject) => {
            loadApplicationsPromise(resolve, reject)
                .then(json => resolve(json))
        })
    ]);
}

export function deleteAdvertisementCall(id, applicationIdFilter) {
    return new Promise((resolve, reject) => {
        deleteAdvertisementPromise(resolve, reject, id)
            .then(__text => loadAdvertisementsPromise(resolve, reject, applicationIdFilter))
            .then(json => resolve(json))
    });
}

export function updateAdvertisementCall(id, name, applicationId, adTypeId, admobAdIds, comments, probability, active, probabilityStartsWithAds, applicationIdFilter) {
    return saveOrUpdateAdvertisementCall(id, name, applicationId, adTypeId, admobAdIds, comments, probability, active, probabilityStartsWithAds, applicationIdFilter);
}

export function changeApplicationIdFilterCall(applicationIdFilter) {
    return new Promise((resolve, reject) => {
        loadAdvertisementsPromise(resolve, reject, applicationIdFilter)
            .then(json => resolve({
                advertisements: json,
                applicationIdFilter: applicationIdFilter
            }))
    });
}
