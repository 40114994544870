import { baseFetch, baseFetchText } from './../common/api';
import { addToast } from './../components/toasts';

function loadReportsPromise(resolve, reject, applicationPackage, code, dateFrom, dateTo) {
    let query = '';
    if (applicationPackage) {
        query += `?applicationPackage=${applicationPackage}`;
    }
    if (code) {
        if (query) query += '&'; else query += '?';
        query += `code=${code}`;
    }
    if (dateFrom) {
        if (query) query += '&'; else query += '?';
        query += `dateFrom=${dateFrom}`;
    }
    if (dateTo) {
        if (query) query += '&'; else query += '?';
        query += `dateTo=${dateTo}`;
    }

    return baseFetch(resolve, reject, '/api/admin/report-scrape/scrapes' + query);
}

function loadApplicationsPackagesPromise(resolve, reject) {
    return baseFetch(resolve, reject, '/api/admin/report-scrape/applications');
}

function deleteReportsPromise(resolve, reject, applicationPackage) {
    return baseFetchText(resolve, reject, '/api/admin/report-scrape/applications/' + applicationPackage, { method: 'DELETE' });
}

function loadReportsCall(applicationPackage, code, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        loadReportsPromise(resolve, reject, applicationPackage, code, dateFrom, dateTo)
            .then(json => resolve(json))
    });
}

export function loadApplicationsPackagesCall() {
    return new Promise((resolve, reject) => {
        loadApplicationsPackagesPromise(resolve, reject)
            .then(json => resolve(json))
    });
}

export function loadApplicationsPackagesAndReportsCall(applicationPackage, code, dateFrom, dateTo) {
    return Promise.all([
        loadApplicationsPackagesCall(),
        loadReportsCall(applicationPackage, code, dateFrom, dateTo)
    ]);
}

export function changeFilterCall(applicationPackage, code, dateFrom, dateTo) {
    return new Promise((resolve, reject) => {
        loadReportsPromise(resolve, reject, applicationPackage, code, dateFrom, dateTo)
            .then(json => resolve({
                inputs: { applicationPackage: applicationPackage, code: code, dateFrom: dateFrom, dateTo: dateTo },
                reports: json
            }))
    });
}

export function removeApplicationPackageScrapesCall(applicationPackage) {
    return new Promise((resolve, reject) => {
        deleteReportsPromise(resolve, reject, applicationPackage)
            .then(__text => loadApplicationsPackagesPromise(resolve, reject))
            .then(json => resolve(json))
    });
}
