import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { parallelActions } from './../../app/store';

import { selectEmergencyState, updateEmergencyAction } from './emergencySlice';

import styles from './emergency.css';

export function Emergency() {
    const emergencyState = useSelector(selectEmergencyState);
    const dispatch = useDispatch();

    let title;
    if (emergencyState.inEmergencyState) {
        title = "Emergency is ON";
    } else {
        title = "Emergency is OFF";
    }
    const onClick = () => { if (window.confirm('Are you sure?')) { dispatch(updateEmergencyAction(!emergencyState.inEmergencyState)); } }

    return (
    <div className="text-center">
        <h1 className="p-4">{title}</h1>
        <button onClick={onClick} className="btn btn-black">Toggle</button>
    </div>
    )
}