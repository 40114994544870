import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { parallelActions } from './../../app/store';

import { timestampToString, activatePopover } from './../common/helpers';

import {
    selectApplicationsState, setInputAction, setUsers,
    openApplicationsListAction, openAddApplicationAction, openEditApplicationAction,
    deleteApplicationAction,
    addApplicationAction,
    updateApplicationAction,
    changeApplicationIsActiveAction
} from './applicationsSlice';

import styles from './applications.css';

function applicationsTable(dispatch, applications, managers) {
    const onApplicationSelect = id => dispatch(openEditApplicationAction(id));
    const onApplicationDelete = (event, id, name) => {
        if (event.target.className.indexOf('no-click') === -1) {
            if ('buttons' in event && event.buttons === 2) {
                if (window.confirm('Are you sure want to remove ' + name + '?')) {
                    dispatch(deleteApplicationAction(id));
                }
            }
        }
    };

    const applicationsElements = applications.map(
        (application, index) => <tr key={application.id} onMouseDown={event => onApplicationDelete(event, application.id, application.name)}>
            <th onClick={event => onApplicationSelect(application.id)} className="bg-info" scope="row">{application.id}</th>
            <td>{application.users.length > 0 ? application.users[0].name + ' ' + application.users[0].surname : ''}</td>
            <td>{application.name}</td>
            <td>{application.admobAppId}</td>
            <td>{application.supremoAppId}</td>
            <td>{application.loadTime}</td>
            <td>{timestampToString(application.createdAt)}</td>
            <td className="no-click">
                <div className="no-click form-check form-switch">
                  <input className="no-click form-check-input form-check-input-black" type="checkbox" checked={application.active} onChange={e => dispatch(changeApplicationIsActiveAction(application.id, index, e.target.checked))}/>
                </div>
            </td>
        </tr>
    );

    return (
    <table className="table">
        <thead>
            <tr className="bg-border">
                <th scope="col">ID</th>
                <th scope="col">Manager</th>
                <th scope="col">Project</th>
                <th scope="col">Admob app id</th>
                <th scope="col">Supremo app id</th>
                <th scope="col">Load time</th>
                <th scope="col">Created at</th>
                <th scope="col">Active</th>
            </tr>
        </thead>
        <tbody onContextMenu={e => {e.preventDefault(); return false;}}>
            {applicationsElements}
        </tbody>
    </table>
    )
}

function applicationForm(applicationsState, dispatch, onSubmit) {
    const managersElements = applicationsState.users.map(
        (manager, index) => <option key={index} value={manager.id}>{manager.name} {manager.surname}</option>
    );

    const managersMap = {};
    applicationsState.users.forEach(manager => managersMap[manager.id] = manager);

    const inputs = {
        users: applicationsState.inputs.users[0],
        onUsers: e => dispatch(setUsers({value: managersMap[e.target.value].id})),
        name: applicationsState.inputs.name,
        onName: e => dispatch(setInputAction({value: e.target.value, input: 'name'})),
        admobAppId: applicationsState.inputs.admobAppId,
        onAdmobAppId: e => dispatch(setInputAction({value: e.target.value, input: 'admobAppId'})),
        loadTime: applicationsState.inputs.loadTime,
        onLoadTime: e => dispatch(setInputAction({value: e.target.value, input: 'loadTime'})),
        comments: applicationsState.inputs.comments,
        onComments: e => dispatch(setInputAction({value: e.target.value, input: 'comments'})),
        onSubmit: event => { event.preventDefault(); onSubmit(event); },
        onBack: () => dispatch(openApplicationsListAction())
    }

    return (
    <div className="container">
        <form onSubmit={inputs.onSubmit}>
            <div className="mb-3">
                <label htmlFor="usersInput" className="form-label">Manager</label>
                <select id="usersInput" className="form-select" name="users"
                        value={inputs.users} onChange={inputs.onUsers}>
                  <option disabled value="-1">Choose manager</option>
                  {managersElements}
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="nameInput" className="form-label">Project name</label>
                <input id="nameInput" className="form-control" type="text" name="name"
                       required min="3"
                       value={inputs.name} onChange={inputs.onName}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="admobAppIdInput" className="form-label">Admob app id</label>
                <input id="admobAppIdInput" className="form-control" type="text" name="admobAppId"
                       required min="3"
                       value={inputs.admobAppId} onChange={inputs.onAdmobAppId}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="loadTimeInput" className="form-label" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content-id="load-time-popover-content">Load time <span className="bg-info custom-icon">&#8263;</span></label>
                <input id="loadTimeInput" className="form-control" type="number" name="loadTime"
                       required min="0"
                       value={inputs.loadTime} onChange={inputs.onLoadTime}
                />
            </div>

            <div id="load-time-popover-content" className="d-none">
              Максимальное время загрузки (миллисекунды)<br/>
              Рекомендуется 5000
            </div>

            <div className="mb-3">
                <label htmlFor="commentsInput" className="form-label">Comments</label>
                <textarea id="commentsInput" className="form-control" name="comments"
                          value={inputs.comments} onChange={inputs.onComments}
                ></textarea>
            </div>

            <div className="row">
                <div className="col-auto">
                    <button type="submit" className="form-control btn btn-black">Save</button>
                </div>
                <div className="col-auto">
                    <button onClick={inputs.onBack} className="form-control btn btn-black" type="button">Back</button>
                </div>
            </div>
        </form>
    </div>
    )
}

function ApplicationsAdd() {
    const applicationsState = useSelector(selectApplicationsState);
    const dispatch = useDispatch();

    // A hook to apply popover after page was rendered
    useEffect(() => activatePopover());

    return applicationForm(
        applicationsState, dispatch,
        e => { if (window.confirm('Are you sure?')) { dispatch(addApplicationAction()) } } // onSubmit
    );
}

function ApplicationsEdit() {
    const applicationsState = useSelector(selectApplicationsState);
    const dispatch = useDispatch();

    // A hook to apply popover after page was rendered
    useEffect(() => activatePopover());

    return applicationForm(
        applicationsState, dispatch,
        e => { if (window.confirm('Are you sure?')) { dispatch(updateApplicationAction()) } } // onSubmit
    );
}

function ApplicationsList() {
    const applicationsState = useSelector(selectApplicationsState);
    const dispatch = useDispatch();

    const onAddNewApplication = () => dispatch(openAddApplicationAction());

    return (
    <div className="overflow-x-scroll">
        <div className="row mb-3 p-3 justify-content-end">
            <div className="col-auto">
                <button onClick={onAddNewApplication} className="btn btn-black">Add new project</button>
            </div>
        </div>

        {applicationsTable(dispatch, applicationsState.applications, applicationsState.users)}
    </div>
    )
}

export function Applications() {
    const applicationsState = useSelector(selectApplicationsState);
    const dispatch = useDispatch();

    switch (applicationsState.action) {
      case 'add':
        return <ApplicationsAdd />
      case 'edit':
        return <ApplicationsEdit />
      case 'list':
      default:
        return <ApplicationsList />
    }
}