import { baseFetch, baseFetchText } from './../common/api';

function loadApplicationsPromise(resolve, reject) {
    return baseFetch(resolve, reject, '/api/admin/applications');
}

function loadManagersPromise(resolve, reject) {
    return baseFetch(resolve, reject, '/api/admin/users?role=MANAGER');
}

function saveNewApplicationPromise(resolve, reject, requestBody) {
    return baseFetchText(resolve, reject, '/api/admin/applications', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestBody) });
}

function changeApplicationIsActivePromise(resolve, reject, id, isActive) {
    return baseFetchText(resolve, reject, `/api/admin/applications/${id}/active/${isActive}`, { method: 'PUT' });
}

function loadApplicationPromise(resolve, reject, id) {
    return baseFetch(resolve, reject, `/api/admin/applications/${id}`);
}

function deleteApplicationPromise(resolve, reject, id) {
    return baseFetchText(resolve, reject, `/api/admin/applications/${id}`, { method: 'DELETE' });
}

function updateApplicationPromise(resolve, reject, id, requestBody) {
    return baseFetchText(resolve, reject, `/api/admin/applications/${id}`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestBody) });
}

function saveOrUpdateApplicationCall(id, users, name, admobAppId, loadTime, comments, active) {
    const requestBody = {
        id: id,
        name: name,
        users: users,
        admobAppId: admobAppId,
        loadTime: loadTime,
        comments: comments,
        active: active
    };

    return new Promise((resolve, reject) => {
        (
            id === undefined
                ? saveNewApplicationPromise(resolve, reject, requestBody)
                : updateApplicationPromise(resolve, reject, id, requestBody)
        )
            .then(__text => loadApplicationsPromise(resolve, reject))
            .then(json => resolve(json))
    });
}

export function loadApplicationsCall() {
    return new Promise((resolve, reject) => {
        loadApplicationsPromise(resolve, reject)
            .then(json => resolve(json))
    });
}

export function loadManagersCall() {
    return new Promise((resolve, reject) => {
        loadManagersPromise(resolve, reject)
            .then(json => resolve(json))
    });
}

export function saveNewApplicationCall(users, name, admobAppId, loadTime, comments) {
    return saveOrUpdateApplicationCall(undefined, users, name, admobAppId, loadTime, comments, undefined);
}

export function changeApplicationIsActiveCall(id, index, isActive) {
    return new Promise((resolve, reject) => {
        changeApplicationIsActivePromise(resolve, reject, id, isActive)
            .then(__text => resolve({ index: index, isActive: isActive }))
    });
}

export function loadApplicationCall(id) {
    return Promise.all([
        new Promise((resolve, reject) => {
            loadApplicationPromise(resolve, reject, id)
                .then(json => resolve(json))
        }),
        new Promise((resolve, reject) => {
            loadManagersPromise(resolve, reject)
                .then(json => resolve(json))
        })
    ]);
}

export function deleteApplicationCall(id) {
    return new Promise((resolve, reject) => {
        deleteApplicationPromise(resolve, reject, id)
            .then(__text => loadApplicationsPromise(resolve, reject))
            .then(json => resolve(json))
    });
}

export function updateApplicationCall(id, users, name, admobAppId, loadTime, comments, active) {
    return saveOrUpdateApplicationCall(id, users, name, admobAppId, loadTime, comments, active);
}
