import { baseFetch, baseFetchText } from './../common/api';

function loadEmergencyPromise(resolve, reject) {
    return baseFetch(resolve, reject, '/api/admin/emergency');
}

function updateEmergencyPromise(resolve, reject, newState) {
    return baseFetchText(resolve, reject, `/api/admin/emergency/active/${newState}`, { method: 'PUT' });
}

export function loadEmergencyCall() {
    return new Promise((resolve, reject) => {
        loadEmergencyPromise(resolve, reject)
            .then(json => resolve(json))
    });
}

export function updateEmergencyCall(newState) {
    return new Promise((resolve, reject) => {
        updateEmergencyPromise(resolve, reject, newState)
            .then(__text => loadEmergencyPromise(resolve, reject))
            .then(json => resolve(json))
    });
}
