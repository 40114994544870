import { addToast } from './../components/toasts';

const host = "https://sprm.dev"

const unauthorized = 'HttpStatus.UNAUTHORIZED';
const forbidden = 'HttpStatus.FORBIDDEN';
const badRequest = 'HttpStatus.BAD_REQUEST';

function getCsrfHeader() {
    const row = document.cookie
        .split('; ')
        .find(row => row.startsWith('XSRF-TOKEN='));

    return row === undefined
        ? {}
        : { 'X-XSRF-TOKEN': row.split('=')[1] };
}

function ApiError(code, jsonPromise) {
    this.code = code;
    this.jsonPromise = jsonPromise;
}

export function baseFetchText(resolve, reject, url, options = {}) {
    return baseFetch(resolve, reject, url, options, response => response.text());
}

export function baseFetch(
        resolve,
        reject,
        url,
        options = {},
        transform = response => response.json(),
        responseHandling = response => {
            const responseStatus = response.status;

            if (responseStatus >= 400) {
                let code;
                if (responseStatus === 401) {
                    code = unauthorized;
                } else if (responseStatus === 403) {
                    code = forbidden;
                } else {
                    code = badRequest;
                }

                throw new ApiError(code, response.json());
            }

            return response;
        }
    ) {

    options.headers = {
        'Accept': 'application/json',
        ...(options.headers || {}),
        ...getCsrfHeader()
    };
    options.redirect = 'error';

    return fetch(host + url, options)
        .then(response => responseHandling(response))
        .then(response => transform(response))
        .catch(error => {
            const code = error.code;

            if ('Failed to fetch' === code && '/login' === url && 'POST' === options.method) {
                addToast('Unable to login. Please verify credentials or ask for support');
            }
            if ('HttpStatus.UNAUTHORIZED' === code) {
                addToast('Unauthorized');
                setTimeout(() => window.location.reload(), 1000);
            }
            if ('HttpStatus.FORBIDDEN' === code) {
                addToast('Forbidden');
            }
            if ('HttpStatus.BAD_REQUEST' === code) {
                error.jsonPromise.then(json => {
                    const detailedMessage = json.message ? ` (${json.message})` : '';
                    const toastText = `Something went wrong${detailedMessage}. Please recreate use-case and ask for support`;

                    addToast(toastText);
                });
            }

            reject({});
        })

}


