import { baseFetch, baseFetchText } from './../common/api';
import { addToast } from './../components/toasts';

function loadUsersPromise(resolve, reject) {
    return baseFetch(resolve, reject, '/api/admin/users');
}

function loadRolesPromise(resolve, reject) {
    return baseFetch(resolve, reject, '/api/admin/roles');
}

function saveNewUserPromise(resolve, reject, requestBody) {
    return baseFetchText(resolve, reject, '/api/admin/users', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestBody) });
}

function changeUserIsActivePromise(resolve, reject, id, isActive) {
    return baseFetchText(resolve, reject, `/api/admin/users/${id}/active/${isActive}`, { method: 'PUT' });
}

function loadUserPromise(resolve, reject, id) {
    return baseFetch(resolve, reject, `/api/admin/users/${id}`);
}

function deleteUserPromise(resolve, reject, id) {
    return baseFetchText(resolve, reject, `/api/admin/users/${id}`, { method: 'DELETE' });
}

function updateUserPromise(resolve, reject, id, requestBody) {
    return baseFetchText(resolve, reject, `/api/admin/users/${id}`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestBody) });
}

function resetUserPasswordPromise(resolve, reject, id) {
    return baseFetchText(resolve, reject, `/api/admin/users/${id}/reset`, { method: 'PUT' });
}

function saveOrUpdateUserCall(id, name, surname, role, comments, active) {
    const requestBody = {
        id: id,
        name: name,
        surname: surname,
        role: role,
        comments: comments,
        active: active
    };

    return new Promise((resolve, reject) => {
        (
            id === undefined
                ? saveNewUserPromise(resolve, reject, requestBody)
                : updateUserPromise(resolve, reject, id, requestBody)
        )
            .then(__text => loadUsersPromise(resolve, reject))
            .then(json => resolve(json))
    });
}

export function loadUsersCall() {
    return new Promise((resolve, reject) => {
        loadUsersPromise(resolve, reject)
            .then(json => resolve(json))
    });
}

export function loadRolesCall() {
    return new Promise((resolve, reject) => {
        loadRolesPromise(resolve, reject)
            .then(json => resolve(json))
    });
}

export function saveNewUserCall(name, surname, role, comments) {
    return saveOrUpdateUserCall(undefined, name, surname, role, comments);
}

export function changeUserIsActiveCall(id, index, isActive) {
    return new Promise((resolve, reject) => {
        changeUserIsActivePromise(resolve, reject, id, isActive)
            .then(__text => resolve({ index: index, isActive: isActive }))
    });
}

export function loadUserCall(id) {
    return Promise.all([
        new Promise((resolve, reject) => {
            loadUserPromise(resolve, reject, id)
                .then(json => resolve(json))
        }),
        new Promise((resolve, reject) => {
            loadRolesPromise(resolve, reject)
                .then(json => resolve(json))
        })
    ]);
}

export function deleteUserCall(id) {
    return new Promise((resolve, reject) => {
        deleteUserPromise(resolve, reject, id)
            .then(__text => loadUsersPromise(resolve, reject))
            .then(json => resolve(json))
    });
}

export function updateUserCall(id, name, surname, role, comments, active) {
    return saveOrUpdateUserCall(id, name, surname, role, comments, active);
}

export function resetUserPasswordCall(id) {
    return new Promise((resolve, reject) => {
        resetUserPasswordPromise(resolve, reject, id)
            .then(__text => {
                addToast('Password reset successfully', 'info');
                resolve({});
            })
    });
}
