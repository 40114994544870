import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setCommonExtraReducerCases } from './../common/reducer';

import { loadEmergencyCall, updateEmergencyCall } from './emergencyApi';

const initialState = {
  inEmergencyState: false
};

const initializeAsyncInternalAction = createAsyncThunk(
  'emergency/init',
  async () => {
    return await loadEmergencyCall();
  }
);

const updateEmergencyAsyncInternalAction = createAsyncThunk(
  'emergency/update-state',
  async (parameters) => {
    return await updateEmergencyCall(parameters.newState);
  }
);

export const emergencySlice = createSlice({
  name: 'emergency',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    setCommonExtraReducerCases(builder, initializeAsyncInternalAction, (state, action) => {
      state.inEmergencyState = action.payload.active;
    });
    setCommonExtraReducerCases(builder, updateEmergencyAsyncInternalAction, (state, action) => {
      state.inEmergencyState = action.payload.active;
    });
  }
});

export const selectEmergencyState = (state) => state.emergency;

export const initializeAction = () => (dispatch, getState) => {
  dispatch(initializeAsyncInternalAction());
};

export const updateEmergencyAction = (newState) => (dispatch, getState) => {
  dispatch(updateEmergencyAsyncInternalAction({
    newState: newState
  }));
};

export default emergencySlice.reducer;