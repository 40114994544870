import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setInputDefaultAction, setCommonExtraReducerCases } from './../common/reducer';

import { loadApplicationsCall, loadManagersCall, saveNewApplicationCall, changeApplicationIsActiveCall, loadApplicationCall, deleteApplicationCall, updateApplicationCall } from './applicationsApi';

function emptyInputs(application = undefined) {
    return {
        id: application === undefined ? '' : application.id,
        name: application === undefined ? '' : application.name,
        admobAppId: application === undefined ? '' : application.admobAppId,
        loadTime: application === undefined ? 0 : application.loadTime,
        comments: application === undefined ? '' : '', //(application.comments === null ? '' : application.comments),
        active: application === undefined ? false : application.active,
        supremoAppId: application === undefined ? '' : application.supremoAppId,
        createdAt: application === undefined ? '' : application.createdAt,
        users: application === undefined ? [-1] : application.users.length > 0 ? application.users.map(user => user.id) : [-1]
    }
}

const initialState = {
  applications: [],
  users: [], // Managers

  inputs: emptyInputs(),

  action: 'list'
};

const initializeAsyncInternalAction = createAsyncThunk(
  'applications/init',
  async () => {
    return await loadApplicationsCall();
  }
);

const openAddNewApplicationAsyncInternalAction = createAsyncThunk(
  'applications/load-managers',
  async () => {
    return await loadManagersCall();
  }
);

const addNewApplicationAsyncInternalAction = createAsyncThunk(
  'applications/add-new-application',
  async (parameters) => {
    return await saveNewApplicationCall(parameters.users, parameters.name, parameters.admobAppId, parameters.loadTime, parameters.comments);
  }
);

const changeApplicationIsActiveAsyncInternalAction = createAsyncThunk(
  'applications/change-new-is-active',
  async (parameters) => {
    return await changeApplicationIsActiveCall(parameters.id, parameters.index, parameters.isActive);
  }
);

const openApplicationAsyncInternalAction = createAsyncThunk(
  'applications/load-application',
  async (parameters) => {
    return await loadApplicationCall(parameters.id);
  }
);

const deleteApplicationAsyncInternalAction = createAsyncThunk(
  'applications/delete-application',
  async (parameters) => {
    return await deleteApplicationCall(parameters.id);
  }
);

const updateApplicationAsyncInternalAction = createAsyncThunk(
  'applications/update-application',
  async (parameters) => {
    return await updateApplicationCall(parameters.id, parameters.users, parameters.name, parameters.admobAppId, parameters.loadTime, parameters.comments, parameters.active);
  }
);

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    setInputAction: setInputDefaultAction,
    setUsers: (state, action) => {
      state.inputs.users[0] = action.payload.value;
    },
    openApplicationsListAction: (state, action) => {
      state.action = 'list';
    }
  },
  extraReducers: (builder) => {
    setCommonExtraReducerCases(builder, initializeAsyncInternalAction, (state, action) => {
      state.action = 'list';
      state.applications = action.payload.elements;
    });
    setCommonExtraReducerCases(builder, openAddNewApplicationAsyncInternalAction, (state, action) => {
      state.action = 'add';
      state.users = action.payload.elements;
      state.inputs = emptyInputs();
    });
    setCommonExtraReducerCases(builder, addNewApplicationAsyncInternalAction, (state, action) => {
      state.action = 'list';
      state.applications = action.payload.elements;
    });
    setCommonExtraReducerCases(builder, changeApplicationIsActiveAsyncInternalAction, (state, action) => {
      state.applications[action.payload.index].active = action.payload.isActive;
    });
    setCommonExtraReducerCases(builder, openApplicationAsyncInternalAction, (state, action) => {
      state.action = 'edit';
      state.inputs = emptyInputs(action.payload[0]);
      state.users = action.payload[1].elements;
    });
    setCommonExtraReducerCases(builder, deleteApplicationAsyncInternalAction, (state, action) => {
      state.action = 'list';
      state.applications = action.payload.elements;
    });
    setCommonExtraReducerCases(builder, updateApplicationAsyncInternalAction, (state, action) => {
      state.action = 'list';
      state.applications = action.payload.elements;
    });
  }
});

export const selectApplicationsState = (state) => state.applications;

export const { setInputAction, setUsers, openApplicationsListAction, changeApplicationIsActiveActionTemp } = applicationsSlice.actions;

export const initializeAction = () => (dispatch, getState) => {
  dispatch(initializeAsyncInternalAction());
};

export const openAddApplicationAction = () => (dispatch, getState) => {
  dispatch(openAddNewApplicationAsyncInternalAction());
};

export const addApplicationAction = () => (dispatch, getState) => {
  const applicationsState = selectApplicationsState(getState());

  if (applicationsState.inputs.users[0] === -1) {
    return;
  }

  dispatch(addNewApplicationAsyncInternalAction({
    ...applicationsState.inputs
  }));
};

export const changeApplicationIsActiveAction = (id, index, isActive) => (dispatch, getState) => {
  dispatch(changeApplicationIsActiveAsyncInternalAction({
    id: id,
    index: index,
    isActive: isActive
  }));
};

export const openEditApplicationAction = (id) => (dispatch, getState) => {
  dispatch(openApplicationAsyncInternalAction({
    id: id
  }));
};

export const deleteApplicationAction = (id) => (dispatch, getState) => {
  dispatch(deleteApplicationAsyncInternalAction({
    id: id
  }));
};

export const updateApplicationAction = () => (dispatch, getState) => {
  const applicationsState = selectApplicationsState(getState());

  if (applicationsState.inputs.users[0] === -1) {
    return;
  }

  dispatch(updateApplicationAsyncInternalAction({
    ...applicationsState.inputs
  }));
};

export default applicationsSlice.reducer;