import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { parallelActions } from './../../app/store';

import { selectLoginState, prepareLoginFormAction } from './../login/loginSlice';

import { usersMeCall, loginAndUsersMeCall, logoutAndUsersMeCall } from './mainApi.js';

const initialState = {
  spinner: true,

  needLoginCheck: true,
  needLoginCheckInProgress: false,

  loggedIn: false,
  me: undefined,

  checked: false,
  loaded: false,
  loading: false,

  selectedPage: undefined,
};

const verifyLoginAsyncInternalAction = createAsyncThunk(
  'main/verify-login',
  async () => {
    return await usersMeCall();
  }
);

const doLoginAndThenVerifyLoginAsyncInternalAction = createAsyncThunk(
  'main/do-login-and-verify-login',
  async (parameters) => {
    return await loginAndUsersMeCall(parameters.username, parameters.password);
  }
);

const doLogoutAndVerifyLoginAsyncInternalAction = createAsyncThunk(
  'main/do-logout-and-verify-login',
  async () => {
    return await logoutAndUsersMeCall();
  }
);

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    showSpinner: (state) => {
        state.spinner = true
    },
    hideSpinner: (state) => {
        state.spinner = false
    },
    selectPageAction: (state, action) => {
        state.selectedPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyLoginAsyncInternalAction.pending, (state) => {
        state.needLoginCheck = true; // Actually is true before this
        state.needLoginCheckInProgress = true;
      })
      builder.addCase(verifyLoginAsyncInternalAction.fulfilled, (state, action) => {
        state.needLoginCheck = false;
        state.needLoginCheckInProgress = false;
        state.loggedIn = action.payload.loggedIn;
        state.me = action.payload.me;
      })
      .addCase(doLoginAndThenVerifyLoginAsyncInternalAction.pending, (state) => {
        state.needLoginCheck = true; // Actually is true before this
        state.needLoginCheckInProgress = true;
      })
      builder.addCase(doLoginAndThenVerifyLoginAsyncInternalAction.fulfilled, (state, action) => {
        state.needLoginCheck = false;
        state.needLoginCheckInProgress = false;
        state.loggedIn = action.payload.loggedIn;
        state.me = action.payload.me;
        state.selectedPage = undefined;

        // Navigate to ADS page
        setTimeout(() => {
            document.getElementById('advertisementsMenu').click();
        },100);
      })
      .addCase(doLogoutAndVerifyLoginAsyncInternalAction.pending, (state) => {
        state.needLoginCheck = true; // Actually is true before this
        state.needLoginCheckInProgress = true;
      })
      builder.addCase(doLogoutAndVerifyLoginAsyncInternalAction.fulfilled, (state, action) => {
        state.needLoginCheck = false;
        state.needLoginCheckInProgress = false;
        state.loggedIn = action.payload.loggedIn;
        state.me = action.payload.me;
      });
  },
});

export const selectMainState = (state) => state.main;

export const { showSpinner, hideSpinner, selectPageAction } = mainSlice.actions;

export const verifyLoginAction = (forceFlag = false) => (dispatch, getState) => {
  const mainState = selectMainState(getState());

  if (forceFlag || !mainState.needLoginCheckInProgress) {
    dispatch(verifyLoginAsyncInternalAction());
  }
};

export const doLoginAndThenVerifyLoginAction = () => (dispatch, getState) => {
  const loginState = selectLoginState(getState());

  dispatch(parallelActions([
    prepareLoginFormAction(),
    doLoginAndThenVerifyLoginAsyncInternalAction({
        username: loginState.inputs.username,
        password: loginState.inputs.password
    })
  ]));
};

export const doLogoutAndVerifyLoginAction = () => (dispatch, getState) => {
  dispatch(doLogoutAndVerifyLoginAsyncInternalAction());
};

export default mainSlice.reducer;