import React from 'react';
import { useSelector } from 'react-redux';

import { selectMainState } from './../main/mainSlice';

import styles from './userInfo.css';

export function UserInfo() {
    const mainState = useSelector(selectMainState);

    return (
    <div>
        <div className="user-info-header">
        </div>
        <div className="user-info-container">
            <div>
                <h5 className="text-center">{mainState.me.name} {mainState.me.surname}</h5>
                <h6 className="text-center color-gray">{mainState.me.role}</h6>
            </div>
        </div>
    </div>
    );
}