import { baseFetch, baseFetchText } from './../common/api';
import { addToast } from './../components/toasts';

import { loginPromise, logoutPromise } from './../login/loginApi';

function loadMePromise(resolve, reject) {
    return baseFetch(
        resolve,
        reject,
        '/api/admin/users/me',
        {},
        response => response,
        response => response // No error handling here
    );
}

export function usersMeCall() {
    return new Promise((resolve, reject) => {
        loadMePromise(resolve, reject)
            // Is used in three places
            .then(response => {
                if (response.status === 401) {
                    resolve({ loggedIn: false });
                } else {
                    return response.json();
                }
            })
            .then(me => resolve({ loggedIn: true, me: me }));
    });
}

export function loginAndUsersMeCall(username, password) {
    return new Promise((resolve, reject) => {
        loginPromise(resolve, reject, username, password)
            // Is used in two places
            .then(__text => loadMePromise(resolve, reject))
            // Is used in three places
            .then(response => {
                if (response.status === 401) {
                    resolve({ loggedIn: false });
                } else {
                    return response.json();
                }
            })
            .then(me => resolve({ loggedIn: true, me: me }));
    });
}

export function logoutAndUsersMeCall() {
    return new Promise((resolve, reject) => {
        logoutPromise()
            .then(__text => {
                resolve({});
                setTimeout(() => window.location.reload(), 10)
            })
    });
}



