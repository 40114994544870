export function setInputDefaultAction(state, action) {
    if (Object.prototype.toString.call(state.inputs[action.payload.input]) === '[object Array]') {
        const array = state.inputs[action.payload.input];
        if (action.payload.value) {
            // Add
            array.push(action.payload.id);
        } else {
            // Remove
            const index = array.indexOf(action.payload.id);
            if (index !== -1) {
                array.splice(index, 1);
            }
        }
    } else {
        state.inputs[action.payload.input] = action.payload.value;
    }
}

export function setCommonExtraReducerCases(builder, asyncInternalAction, fulfilledCallback) {
    builder.addCase(asyncInternalAction.pending, (state, action) => {
        state.loaded = false;
        state.loading = true;
    });

    builder.addCase(asyncInternalAction.fulfilled, (state, action) => {
        state.loaded = true;
        state.loading = false;

        fulfilledCallback(state, action);
    });

    builder.addCase(asyncInternalAction.rejected, (state, action) => {
        state.loaded = true;
        state.loading = false;
    });
}