import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setInputDefaultAction, setCommonExtraReducerCases } from './../common/reducer';

import { loadUsersCall, loadRolesCall, saveNewUserCall, changeUserIsActiveCall, loadUserCall, deleteUserCall, updateUserCall, resetUserPasswordCall } from './usersApi';

function emptyInputs(user = undefined) {
    return {
        id: user === undefined ? '' : user.id,
        name: user === undefined ? '' : user.name,
        surname: user === undefined ? '' : user.surname,
        role: user === undefined ? '' : user.role,
        comments: user === undefined ? '' : (user.comments === null ? '' : user.comments),
        active: user === undefined ? false : user.active,
    }
}

const initialState = {
  users: [],
  roles: [],

  inputs: emptyInputs(),

  action: 'list'
};

const initializeAsyncInternalAction = createAsyncThunk(
  'users/init',
  async () => {
    return await loadUsersCall();
  }
);

const openAddNewUserAsyncInternalAction = createAsyncThunk(
  'users/load-roles',
  async () => {
    return await loadRolesCall();
  }
);

const addNewUserAsyncInternalAction = createAsyncThunk(
  'users/add-new-user',
  async (parameters) => {
    return await saveNewUserCall(parameters.name, parameters.surname, parameters.role, parameters.comments);
  }
);

const changeUserIsActiveAsyncInternalAction = createAsyncThunk(
  'users/change-new-is-active',
  async (parameters) => {
    return await changeUserIsActiveCall(parameters.id, parameters.index, parameters.isActive);
  }
);

const openUserAsyncInternalAction = createAsyncThunk(
  'users/load-user',
  async (parameters) => {
    return await loadUserCall(parameters.id);
  }
);

const deleteUserAsyncInternalAction = createAsyncThunk(
  'users/delete-user',
  async (parameters) => {
    return await deleteUserCall(parameters.id);
  }
);

const updateUserAsyncInternalAction = createAsyncThunk(
  'users/update-user',
  async (parameters) => {
    return await updateUserCall(parameters.id, parameters.name, parameters.surname, parameters.role, parameters.comments, parameters.active);
  }
);

const resetUserPasswordAsyncInternalAction = createAsyncThunk(
  'users/reset-user-password',
  async (parameters) => {
    return await resetUserPasswordCall(parameters.id);
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setInputAction: setInputDefaultAction,
    openUsersListAction: (state, action) => {
      state.action = 'list';
    }
  },
  extraReducers: (builder) => {
    setCommonExtraReducerCases(builder, initializeAsyncInternalAction, (state, action) => {
      state.action = 'list';
      state.users = action.payload.elements;
    });
    setCommonExtraReducerCases(builder, openAddNewUserAsyncInternalAction, (state, action) => {
      state.action = 'add';
      state.roles = action.payload.elements;
      state.inputs = emptyInputs();
    });
    setCommonExtraReducerCases(builder, addNewUserAsyncInternalAction, (state, action) => {
      state.action = 'list';
      state.users = action.payload.elements;
    });
    setCommonExtraReducerCases(builder, changeUserIsActiveAsyncInternalAction, (state, action) => {
      state.users[action.payload.index].active = action.payload.isActive;
    });
    setCommonExtraReducerCases(builder, openUserAsyncInternalAction, (state, action) => {
      state.action = 'edit';
      state.inputs = emptyInputs(action.payload[0]);
      state.roles = action.payload[1].elements;
    });
    setCommonExtraReducerCases(builder, deleteUserAsyncInternalAction, (state, action) => {
      state.action = 'list';
      state.users = action.payload.elements;
    });
    setCommonExtraReducerCases(builder, updateUserAsyncInternalAction, (state, action) => {
      state.action = 'list';
      state.users = action.payload.elements;
    });
    setCommonExtraReducerCases(builder, resetUserPasswordAsyncInternalAction, (state, action) => {
      // Do nothing
    });
  }
});

export const selectUsersState = (state) => state.users;

export const { setInputAction, openUsersListAction, changeUserIsActiveActionTemp } = usersSlice.actions;

export const initializeAction = () => (dispatch, getState) => {
  const usersState = selectUsersState(getState());

  dispatch(initializeAsyncInternalAction());
};

export const openAddUserAction = () => (dispatch, getState) => {
  dispatch(openAddNewUserAsyncInternalAction());
};

export const addUserAction = () => (dispatch, getState) => {
  const usersState = selectUsersState(getState());

  if (usersState.inputs.role === '') {
    return;
  }

  dispatch(addNewUserAsyncInternalAction({
    ...usersState.inputs
  }));
};

export const changeUserIsActiveAction = (id, index, isActive) => (dispatch, getState) => {
  dispatch(changeUserIsActiveAsyncInternalAction({
    id: id,
    index: index,
    isActive: isActive
  }));
};

export const openEditUserAction = (id) => (dispatch, getState) => {
  dispatch(openUserAsyncInternalAction({
    id: id
  }));
};

export const deleteUserAction = (id) => (dispatch, getState) => {
  dispatch(deleteUserAsyncInternalAction({
    id: id
  }));
};

export const updateUserAction = () => (dispatch, getState) => {
  const usersState = selectUsersState(getState());

  if (usersState.inputs.role === '') {
    return;
  }

  dispatch(updateUserAsyncInternalAction({
    ...usersState.inputs
  }));
};

export const resetUserPasswordAction = () => (dispatch, getState) => {
  const usersState = selectUsersState(getState());

  dispatch(resetUserPasswordAsyncInternalAction({
    id: usersState.inputs.id
  }));
};

export default usersSlice.reducer;