import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { parallelActions } from './../../app/store';

import { timestampToString } from './../common/helpers';

import {
    selectUsersState, setInputAction,
    openUsersListAction, openAddUserAction, openEditUserAction,
    deleteUserAction,
    addUserAction,
    updateUserAction,
    changeUserIsActiveAction, resetUserPasswordAction
} from './usersSlice';

import styles from './users.css';

function usersTable(dispatch, users) {
    const onUserSelect = id => dispatch(openEditUserAction(id));
    const onUserDelete = (event, id, name, surname) => {
        if (event.target.className.indexOf('no-click') === -1) {
            if ('buttons' in event && event.buttons === 2) {
                if (window.confirm('Are you sure want to remove ' + name + ' ' + surname + '?')) {
                    dispatch(deleteUserAction(id));
                }
            }
        }
    };

    const usersElements = users.map(
        (user, index) => <tr key={user.id} onMouseDown={event => onUserDelete(event, user.id, user.name, user.surname)}>
            <th onClick={event => onUserSelect(user.id)} className="bg-info" scope="row">{user.id}</th>
            <td>{user.role}</td>
            <td>{user.name}</td>
            <td>{user.surname}</td>
            <td>{user.login}</td>
            <td>{timestampToString(user.lastSession)}</td>
            <td className="no-click">
                <div className="no-click form-check form-switch">
                  <input className="no-click form-check-input form-check-input-black" type="checkbox" checked={user.active} onChange={e => dispatch(changeUserIsActiveAction(user.id, index, e.target.checked))}/>
                </div>
            </td>
        </tr>
    );

    return (
    <table className="table">
        <thead>
            <tr className="bg-border">
                <th scope="col">ID</th>
                <th scope="col">Role</th>
                <th scope="col">Name</th>
                <th scope="col">Surname</th>
                <th scope="col">Login</th>
                <th scope="col">Last Session</th>
                <th scope="col">Active</th>
            </tr>
        </thead>
        <tbody onContextMenu={e => {e.preventDefault(); return false;}}>
            {usersElements}
        </tbody>
    </table>
    )
}

function userForm(usersState, dispatch, onSubmit) {
    const rolesElements = usersState.roles.map(
        (role, index) => <option key={index} value={role.value}>{role.text}</option>
    );

    const inputs = {
        name: usersState.inputs.name,
        onName: e => dispatch(setInputAction({value: e.target.value, input: 'name'})),
        surname: usersState.inputs.surname,
        onSurname: e => dispatch(setInputAction({value: e.target.value, input: 'surname'})),
        role: usersState.inputs.role,
        onRole: e => dispatch(setInputAction({value: e.target.value, input: 'role'})),
        comments: usersState.inputs.comments,
        onComments: e => dispatch(setInputAction({value: e.target.value, input: 'comments'})),
        onSubmit: event => { event.preventDefault(); onSubmit(event); },
        onBack: () => dispatch(openUsersListAction()),
        onReset: () => dispatch(resetUserPasswordAction())
    }

    let resetButtonBlock = '';
    if (usersState.inputs.id !== '') {
        resetButtonBlock = (<div className="col-md-6">
             <div className="row justify-content-end">
                 <div className="col-auto">
                     <button onClick={inputs.onReset} className="form-control btn btn-black" type="button">Reset</button>
                 </div>
             </div>
         </div>)
    }

    return (
    <div className="container">
        <form onSubmit={inputs.onSubmit}>
            <div className="row mb-3">
                <div className="col-md-6">
                    <label htmlFor="nameInput" className="form-label">Name</label>
                    <input id="nameInput" className="form-control" type="text" name="name"
                           required min="3" pattern="[a-zA-Z\s]+"
                           value={inputs.name} onChange={inputs.onName}
                    />
                </div>
                <div className="col-md-6">
                    <label htmlFor="surnameInput" className="form-label">Surname</label>
                    <input id="surnameInput" className="form-control" type="text" name="surname"
                           required min="3" pattern="[a-zA-Z\s]+"
                           value={inputs.surname} onChange={inputs.onSurname}
                    />
                </div>
            </div>

            <div className="mb-3">
                <label htmlFor="roleInput" className="form-label">Role</label>
                <select id="roleInput" className="form-select" name="role" disabled={usersState.inputs.id === '' ? "" : "disabled"}
                        value={inputs.role} onChange={inputs.onRole}>
                  <option disabled value="">Choose role</option>
                  {rolesElements}
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="commentsInput" className="form-label">Comments</label>
                <textarea id="commentsInput" className="form-control" name="comments"
                          value={inputs.comments} onChange={inputs.onComments}
                ></textarea>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-auto">
                            <button type="submit" className="form-control btn btn-black">Save</button>
                        </div>
                        <div className="col-auto">
                            <button onClick={inputs.onBack} className="form-control btn btn-black" type="button">Back</button>
                        </div>
                    </div>
                </div>

                {resetButtonBlock}
            </div>
        </form>
    </div>
    )
}

function UsersAdd() {
    const usersState = useSelector(selectUsersState);
    const dispatch = useDispatch();

    return userForm(
        usersState, dispatch,
        e => { if (window.confirm('Are you sure?')) { dispatch(addUserAction()) } } // onSubmit
    );
}

function UsersEdit() {
    const usersState = useSelector(selectUsersState);
    const dispatch = useDispatch();

    return userForm(
        usersState, dispatch,
        e => { if (window.confirm('Are you sure?')) { dispatch(updateUserAction()) } } // onSubmit
    );
}

function UsersList() {
    const usersState = useSelector(selectUsersState);
    const dispatch = useDispatch();

    const onAddNewUser = () => dispatch(openAddUserAction());

    return (
    <div className="overflow-x-scroll">
        <div className="row mb-3 p-3 justify-content-end">
            <div className="col-auto">
                <button onClick={onAddNewUser} className="btn btn-black">Add new user</button>
            </div>
        </div>

        {usersTable(dispatch, usersState.users)}
    </div>
    )
}

export function Users() {
    const usersState = useSelector(selectUsersState);
    const dispatch = useDispatch();

    switch (usersState.action) {
      case 'add':
        return <UsersAdd />
      case 'edit':
        return <UsersEdit />
      case 'list':
      default:
        return <UsersList />
    }
}