import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { setInputDefaultAction, setCommonExtraReducerCases } from './../common/reducer';

import { loadApplicationsPackagesAndReportsCall, changeFilterCall, removeApplicationPackageScrapesCall } from './reportsApi';

function convertToTwoDigits(number) {
  return number.toString().padStart(2, '0');
}

function formatDate(date) {
  return [
    date.getFullYear(),
    convertToTwoDigits(date.getMonth() + 1),
    convertToTwoDigits(date.getDate()),
  ].join('-');
}

function initializeInputs() {
  return {
    applicationPackage: undefined,
    code: undefined,
    dateFrom: formatDate(new Date()),
    dateTo: undefined
  };
}

const initialState = {
  applicationsPackages: [],
  reports: undefined,

  inputs: initializeInputs()
};

const initializeAsyncInternalAction = createAsyncThunk(
  'reports/init',
  async (parameters) => {
    return await loadApplicationsPackagesAndReportsCall(parameters.applicationPackage, parameters.code, parameters.dateFrom, parameters.dateTo);
  }
);

const changeFilterAsyncInternalAction = createAsyncThunk(
  'reports/change-filter',
  async (parameters) => {
    return await changeFilterCall(parameters.applicationPackage, parameters.code, parameters.dateFrom, parameters.dateTo);
  }
);

const removeApplicationPackageScrapesAsyncInternalAction = createAsyncThunk(
  'reports/remove-application-package',
  async (parameters) => {
    return await removeApplicationPackageScrapesCall(parameters.applicationPackage);
  }
);

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setInputAction: setInputDefaultAction,
  },
  extraReducers: (builder) => {
    setCommonExtraReducerCases(builder, initializeAsyncInternalAction, (state, action) => {
      state.inputs = initializeInputs();
      state.applicationsPackages = action.payload[0];
      state.reports = action.payload[1];
    });
    setCommonExtraReducerCases(builder, changeFilterAsyncInternalAction, (state, action) => {
      state.inputs = action.payload.inputs;
      state.reports = action.payload.reports;
    });
    setCommonExtraReducerCases(builder, removeApplicationPackageScrapesAsyncInternalAction, (state, action) => {
      state.inputs.applicationPackage = undefined;
      state.applicationsPackages = action.payload;
      state.reports = undefined;
    });
  }
});

export const selectReportsState = (state) => state.reports;

export const { setInputAction } = reportsSlice.actions;

export const initializeAction = () => (dispatch, getState) => {
  const reportsState = selectReportsState(getState());

  dispatch(initializeAsyncInternalAction({
    applicationPackage: reportsState.inputs.applicationPackage,
    code: reportsState.inputs.code,
    dateFrom: reportsState.inputs.dateFrom,
    dateTo: reportsState.inputs.dateTo
  }));
};

export const changeApplicationPackageFilter = (applicationPackageFilter) => (dispatch, getState) => {
  const reportsState = selectReportsState(getState());

  dispatch(changeFilterAsyncInternalAction({
    applicationPackage: applicationPackageFilter,
    code: reportsState.inputs.code,
    dateFrom: reportsState.inputs.dateFrom,
    dateTo: reportsState.inputs.dateTo
  }));
};

export const changeCodeFilter = (codeFilter) => (dispatch, getState) => {
  const reportsState = selectReportsState(getState());

  dispatch(changeFilterAsyncInternalAction({
    applicationPackage: reportsState.inputs.applicationPackage,
    code: codeFilter,
    dateFrom: reportsState.inputs.dateFrom,
    dateTo: reportsState.inputs.dateTo
  }));
};

export const changeFromFilter = (dateFrom) => (dispatch, getState) => {
  const reportsState = selectReportsState(getState());

  dispatch(changeFilterAsyncInternalAction({
    applicationPackage: reportsState.inputs.applicationPackage,
    code: reportsState.inputs.code,
    dateFrom: dateFrom,
    dateTo: reportsState.inputs.dateTo
  }));
};

export const changeToFilter = (dateTo) => (dispatch, getState) => {
  const reportsState = selectReportsState(getState());

  dispatch(changeFilterAsyncInternalAction({
    applicationPackage: reportsState.inputs.applicationPackage,
    code: reportsState.inputs.code,
    dateFrom: reportsState.inputs.dateFrom,
    dateTo: dateTo
  }));
};

export const removeApplicationPackageScrapes = (applicationPackageFilter) => (dispatch, getState) => {
  const reportsState = selectReportsState(getState());

  if (reportsState.inputs.applicationPackage) {
    dispatch(removeApplicationPackageScrapesAsyncInternalAction({
      applicationPackage: reportsState.inputs.applicationPackage
    }));
  }
};

export default reportsSlice.reducer;